import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, HeadProps } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { H2, P, Layout, Grid, AboutMasthead, AboutIntro, Team, Card, Investors, TeamBackground, HeadTags } from '@components'

interface PageProps {
  data: Queries.AboutPageQuery
}

const AboutPage = ({ data: { sanityPage, sanitySiteSettings, allSanityTeam } }: PageProps) => {
  if (!sanityPage || !sanitySiteSettings) return <></>
  const { title, _rawCopy, investors, priorEmployers, sprites } = sanityPage
  const { announcementRibbon } = sanitySiteSettings

  const [t, setT] = useState(0)
  useScrollPosition(
    ({ currPos }) => setT(currPos.y), // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )

  return (
    <Layout>
      {!!announcementRibbon && <Spacer />}
      <Grid>
        <AboutMasthead image={sprites?.find(sprite => sprite?.key === 'masthead-team')?.image?.asset?.url || ''}>
          <H2>Who We Are</H2>
          <P>
            We didn’t just set out to change how brands do business or build loyalty — we’re reinventing global commerce from the ground up. We believe in the power of equitable financial systems. We believe in the power of owning your own
            infrastructure. We believe in the power of passionate brands that will change the world.
          </P>
        </AboutMasthead>

        <AboutIntro />

        <Team {...{ allSanityTeam, t }} />

        <Investors {...{ investors }} />

        <TeamBackground {...{ priorEmployers }} />
      </Grid>
    </Layout>
  )
}

const Spacer = styled.div`
  height: 40px;
`
export const Head = (props: HeadProps) => (
  <HeadTags {...props}>
    <link rel="canonical" href="https://usepower.com/about" />
  </HeadTags>
)

export const query = graphql`
  query AboutPage {
    allSanityTeam {
      nodes {
        name
        title
        portrait {
          asset {
            url
          }
        }
      }
    }
    sanitySiteSettings {
      announcementRibbon
    }
    sanityPage(slug: { current: { eq: "about" } }) {
      title
      slug {
        current
      }
      _rawCopy
      sprites {
        key
        altText
        image {
          asset {
            url
          }
        }
      }
      investors {
        name
        link
        logo {
          asset {
            url
          }
        }
      }
      priorEmployers {
        name
        link
        logo {
          asset {
            url
          }
        }
      }
    }
  }
`

export default AboutPage
